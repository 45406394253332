'use client'

import { AnalyticEvent, AnalyticsEventsFunctions, TrackEvent } from '@hermes/types'
import { getPublicConfigVariable } from '@lib/utils/getConfigVariable'
import { GoogleTagManager, sendGTMEvent } from '@next/third-parties/google'
import moment from 'moment'
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useUser } from './userContext'

interface AnalyticsProviderProps {
  children: React.ReactNode
}

type AnalyticsContextType = {
  trackEvent: TrackEvent
}

const AnalyticsContext = createContext<AnalyticsContextType>({
  trackEvent: () => {}
})

const AnalyticsProvider = ({ children }: AnalyticsProviderProps) => {
  const { user: userInfo } = useUser()
  const [analyticsReady, setAnalyticsReady] = useState(false)
  const [eventsQueue, setEventsQueue] = useState<any>([])
  const memoizedEventsQueue = useMemo(() => eventsQueue, [eventsQueue.length])

  const [events, setEvents] = useState<AnalyticsEventsFunctions | undefined>()

  const analyticsEnabled = Boolean(process.env.NEXT_PUBLIC_ENABLE_ANALYTICS) ?? false

  const eventsFunctions: AnalyticsEventsFunctions = {
    PRODUCT_PAGE_VIEWED: () => {},
    ELEMENT_CLICKED: () => {},
    SELLER_LIST_PAGE_VIEWED: () => {},
    MORE_FROM_SELLER_PAGE_VIEWED: () => {},
    FILTER_CHANGED: () => {},
    SORTING_CHANGED: () => {},
    SELLER_LISTING_SELECTED: () => {},
    SIGN_UP_EMAIL_STARTED: () => {},
    VIEW_BOOK_DETAILS: (params: any) => {},
    SUCCESS_AUTH: (params: any) => {},
    INTERCOM_OPEN: () => {},
    INTERCOM_UPDATE: () => {},
    INTERCOM_SHUTDOWN: () => {},
    SEARCH_CLICK_BOOK: () => {},
    CHECKOUT_SUCCESS_LEARN_MORE: () => {},
    ARTICLES_PAGE_VIEW: () => {},
    PDP_AUTHOR_STORE: () => {},
    SELL_BOOK_RECOMMENDED_PRICE: () => {},
    USER_PROFILE_PAGE_VIEW: () => {},
    USER_PROFILE_CLICK_BOOK: () => {},
    ADD_BOOK_TO_WAITING_LIST: () => {},
    SEARCH_APPLY_FILTER: () => {},
    PDP_CLICK_OTHER_VERSION: () => {},
    PDP_CLICK_MORE_FROM_AUTHOR_BOOK: () => {},
    ADD_MORE_SALE: () => {},
    PDP_CLICK_AUTHOR: () => {},
    PDP_CLICK_OTHER_VERSION_BUYBOX: () => {},
    BOOK_SELLERS_CHANGE_SORTING: () => {},
    PDP_PAGE_VIEW: () => {},
    BUY_USED_SEND_REQUEST_NEW_CUSTOMER: () => {},
    BUY_USED_SEND_REQUEST: (params: any) => {
      sendGTMEvent({ event: 'purchase' })
    },
    CHECKOUT_DISCOUNT_CODE: () => {},
    SHOW_BLOG_ARTICLE: () => {},
    SEARCH_APPLY_SORTING: () => {},
    SIGN_UP_DONE: () => {},
    SIGN_UP_EMAIL_USER_VERIFIED: () => {},
    PDP_ADD_TO_CART: (params: any) => {},
    LOGOUT_PUSH: () => {},
    GLOBAL_SELL_BOOK: () => {},
    ACCEPT_REQUEST: () => {},
    BUY_USED_PICK_SELLER: () => {},
    SIGN_UP_TRACK_EVENTS: () => {},
    SIGN_UP_EMAIL_REGISTER: () => {},
    SIGN_UP_SOCIAL_STARTED: () => {},
    NEW_CUSTOMER: () => {},
    LOGIN_MIGRATION_STARTED: () => {},
    SELL_USED_BOOK_ADDED: () => {},
    SELL_USED_CREATE_BOOK: () => {},
    AMP_SESSION_START: () => {},
    PDP_BROWSE_USED: (params: any) => {},
    SELL_THIS_BOOK_USED: () => {},
    PDP_CAROUSEL_YOU_LIKE: () => {},
    USER_DATA_INIT: () => {},
    FRONTPAGE_CAROUSEL: () => {},
    FRONTPAGE_BANNER: () => {},
    FRONTPAGE_ARTICLE_CAROUSEL: () => {},
    ARTICLE_CAROUSEL: () => {},
    ARTICLE_BANNER: () => {},
    ARTICLE_CTA_BUTTONS: () => {},
    PDP_LEAVE_REVIEW: () => {},
    LOGIN_MIGRATION_SUCCESSFULL: () => {},
    CART_INCREMENT_QUANTITY: () => {},
    CART_DECREMENT_QUANTITY: () => {},
    CART_CHECKOUT: () => {},
    FRONTPAGE_SEARCH: () => {},
    MESSAGES_ACCEPT_REQUEST: () => {},
    REQUEST_SKIPPED_MORE_FROM_SELLER: () => {},
    REQUEST_ADDED_PRODUCT: () => {},
    REQUEST_REMOVED_PRODUCT: () => {},
    PRODUCT_CLICKED: () => {},
    PRODUCT_ADDED_TO_CART: () => {},
    PRODUCT_REMOVED_FROM_CART: () => {},
    CHECKOUT_PAGE_VIEWED: () => {},
    PRODUCT_PAGE_CLICKED_MORE_VERSIONS: () => {},
    SENTRY_CAPTURE_EXCEPTION: (params: any) => {},
    PURCHASE: () => {}
  }

  const sendEvents = () => {
    eventsQueue.forEach((fn: AnalyticEvent) => fn())
    setEventsQueue([])
  }

  const trackEvent: TrackEvent = (eventName, params) => {
    if (analyticsEnabled && events && events[eventName]) {
      const selectedFunction = events[eventName]

      if (typeof selectedFunction === 'function') {
        return selectedFunction(params as any)
      }
    }

    setEventsQueue([...eventsQueue, () => eventsFunctions[eventName](params as any)])
  }

  const initializeDataLayerGTM = useCallback(() => {
    if (userInfo) {
      const userData = {
        event: 'userData',
        userUuid: userInfo.guid,
        userName: `${userInfo.firstName} ${userInfo.lastName}`,
        userEmail: userInfo.email,
        userCreated: moment(userInfo.createdAt).unix(),
        intercomUserHash: userInfo.intercomUserHash,
        intercomAppId: getPublicConfigVariable('INTERCOM_APP_ID')
      }
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push(userData)
    }
  }, [userInfo])

  useEffect(() => {
    setEvents(eventsFunctions)
    setAnalyticsReady(true)
  }, [])

  useEffect(() => {
    if (analyticsReady) {
      sendEvents()
    }
  }, [analyticsReady, memoizedEventsQueue])

  useEffect(() => {
    initializeDataLayerGTM()
  }, [userInfo])

  return (
    <AnalyticsContext.Provider value={{ trackEvent }}>
      <GoogleTagManager gtmId={process.env.NEXT_PUBLIC_GTM_ID || ''} preview={process.env.NEXT_PUBLIC_GTM_ENV ?? undefined} auth={process.env.NEXT_PUBLIC_GTM_AUTH ?? undefined} />
      {children}
    </AnalyticsContext.Provider>
  )
}

export const useAnalytics = () => useContext(AnalyticsContext)

export default AnalyticsProvider
