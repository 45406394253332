'use client'

import { Box, Button, Typography } from '@hermes/web-components'
import { useLocale } from '@hooks/useLocale'
import useTranslateMessage from '@hooks/useTranslateMessage'
import { useAnalytics } from '@lib/contexts/analyticsContext'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { FC, useEffect } from 'react'
import { ErrorBlockContainer, ErrorPageContainer, ImageBlock } from './Error.styles'

interface ErrorProps {
  statusCode?: number
  message?: string
  error?: Error & { digest?: string }
  reset?: () => void
}

export const ErrorContainer: FC<ErrorProps> = ({ statusCode, message, error, reset }) => {
  const analytics = useAnalytics()
  const locale = useLocale()
  const path = usePathname()
  const { t } = useTranslateMessage()
  useEffect(() => {
    analytics.trackEvent('SENTRY_CAPTURE_EXCEPTION', {
      name: `${message} (/${locale}${path})`,
      message: `${statusCode}, ${error} - ${locale}${path}`
    })
  }, [])

  return (
    <>
      <ErrorPageContainer maxWidth="xl">
        <Box flex={1} alignSelf="center">
          <Box maxWidth="375px">
            <Typography variant="h1" fontSize="24px" mb="8px">
              {statusCode === 404 ? t({ id: '404.title' }) : t({ id: 'error.title' })}
            </Typography>
            <Typography color="#878E97" mb="36px">
              {statusCode === 404 ? t({ id: '404.text' }) : t({ id: 'error.generic' })}
            </Typography>
            <ErrorBlockContainer>
              <Typography color="#878E97" mr="4px" fontSize="12px">
                Error message:
              </Typography>
              <Typography variant="h5" color="black" fontWeight={500}>
                Error: Request failed {statusCode && `with error code ${statusCode}`}
              </Typography>
              <Typography variant="h5" color="black" fontWeight={500}>
                Error: Last occurred at {new Date().toLocaleTimeString()}
              </Typography>
            </ErrorBlockContainer>
            <Box display="flex" flexDirection={'row'} gap={'16px'}>
              <Link style={{ textDecoration: 'none' }} href={`/${locale}`}>
                <Button variant="contained">
                  {t({ id: '404.button.goToHome', defaultMessage: 'Go to homepage' })}
                </Button>
              </Link>
              {reset && (
                <Button variant="contained" onClick={() => reset()}>
                  {t({ id: 'tryagain' })}
                </Button>
              )}
            </Box>
          </Box>
        </Box>
        <ImageBlock />
      </ErrorPageContainer>
    </>
  )
}
