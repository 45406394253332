'use client'

import React from 'react'
import { Header } from '@components/Header'
import { Providers } from '@components/Providers'
import { ErrorContainer } from 'containers/Error/Error'

interface NotFoundProps {
  locale: string
  message: string
  translations: Record<string, string>
}

export const NotFound: React.FC<NotFoundProps> = ({
  locale,
  message,
  translations
}: {
  locale: string
  message: string
  translations: Record<string, string>
}) => (
  <>
    <Providers translations={translations} locale={locale}>
      <Header />
      <ErrorContainer statusCode={404} message={message} />
    </Providers>
  </>
)
