'use client'

import React from 'react'
import { IntlProvider } from 'react-intl'
import 'react-loading-skeleton/dist/skeleton.css'
import 'swiper/css'
import 'swiper/css/pagination'

import { theme, ThemeProvider } from '@hermes/web-components'
import AnalyticsProvider from '@lib/contexts/analyticsContext'
import { PrevRouteProvider } from '@lib/contexts/previousRouterContext'
import { UserProvider } from '@lib/contexts/userContext'

interface ProvidersProps {
  children: React.ReactNode
  translations: Record<string, string>
  locale: string
}

export const Providers: React.FC<ProvidersProps> = ({ children, translations, locale }) => (
  <IntlProvider
    locale={locale}
    messages={translations}
    onError={(err) => {
      if (err.code === 'MISSING_TRANSLATION' || err.code === 'MISSING_DATA') {
        console.warn('Missing translation', err.message)
        return
      }
      throw err
    }}
  >
    <PrevRouteProvider>
      <UserProvider locale={locale}>
        <ThemeProvider theme={theme}>
          <AnalyticsProvider>{children}</AnalyticsProvider>
        </ThemeProvider>
      </UserProvider>
    </PrevRouteProvider>
  </IntlProvider>
)
